import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import educator1 from '../Assets/educator1.png';
import educator2 from '../Assets/educator2.png';
import educator3 from '../Assets/educator3.png';
import educator4 from '../Assets/educator4.png';
import useBreakpoints from '../Components/useBreakpoints';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import facef from '../Assets/facef.png';
import facem from '../Assets/facem.png';

const educators = [
  {
    img: educator1,
    width: '110px',
  },
  {
    img: educator2,
    width: '100px',
  },
  {
    img: educator3,
    width: '100px',
  },
  {
    img: educator4,
    width: '100px',
  },
];

const items = [
  {
    id: 1,
    text: 'The kind of personal attention Edvision provides to its students is impeccable! Individual attention provided for the students in the batch.The institute takes pains to appoint the faculty. In my experience, the faculty operates with a more personalized approach and tries to drive each and every student to do more.',
    name: 'Ankitha Mehta',
    designation: 'Student',
    address: 'Reva University',
    gender: 'F',
  },
  {
    id: 2,
    text: 'It feels amazing when I am able to deduce and understand concepts using the basics I studied in this institute. Edvision provides study material which can help you to get selected in entrance exam.They”ll help you out in every aspect. In this exceedingly commercialized country,Edvision focuses on QUALITY, which is highly recommended in a progressing world.',
    name: 'Prena Chawala',
    designation: 'Student',
    address: 'Bangalore International School',
    gender: 'F',
  },
  {
    id: 3,
    text: 'A more user-friendly platform was Edvision. It had a simpler interface and was more familiar to use. As a teacher, I was able to figure out the platform options, which streamlined my teaching experience. For teachers like me, who dont have technical expertise, Edvision is a great teaching platform.',
    name: 'Aman S',
    designation: 'Teacher',
    address: 'Baldwin International School',
    gender: 'M',
  },
  {
    id: 4,
    text: 'Teaching at Edvision has been an enriching experience. I have adapted my style to the changing dynamics of the classroom. Being a part of worthwhile teaching is both an honor and a humbling experience. This is a great place to work.',
    name: 'Harini V',
    designation: 'Teacher',
    address: 'Rashtriya Milittary School',
    gender: 'F',
  },
  {
    id: 5,
    text: 'Teaching at Edvision has been an enriching experience. I have adapted my style to the changing dynamics of the classroom. Being a part of worthwhile teaching is both an honor and a humbling experience. This is a great place to work.',
    name: 'Harsh Y',
    designation: 'Teacher',
    address: 'Rashtriya Milittary School',
    gender: 'M',
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ButtonGroup = ({ previous, next }) => (
  <Box width="100%">
    <Box display="flex" justifyContent="space-between">
      <IconButton onClick={previous}>
        <IoIosArrowRoundBack />
      </IconButton>
      <IconButton onClick={next}>
        <IoIosArrowRoundForward />
      </IconButton>
    </Box>
  </Box>
);

const CarouselCard = ({
  text,
  name,
  designation,
  address,
  classes,
  gender,
}) => {
  const { sm } = useBreakpoints();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography align="left" marginBottom="20px">
          <i>{text}</i>
        </Typography>
        <Box display="flex" alignItems="center">
          <img
            src={gender === 'F' ? facef : facem}
            alt="face"
            height={sm ? '100px' : '60px'}
            width={sm ? '100px' : '60px'}
            style={{ marginRight: '20px' }}
          />
          <Box>
            <Typography className="bold" align="left">
              {name}
            </Typography>
            <Typography align="left">{designation}</Typography>
            <Typography align="left">{address}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default function CarouselSection() {
  const classes = useStyles();
  const { lg, xl } = useBreakpoints();

  return (
    <section className={classes.section}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        marginX={xl ? '500px' : lg ? '250px' : '28px'}
      >
        <Typography fontWeight="bold" color="#002f6c" fontSize="35px">
          We're in classrooms just like yours.
        </Typography>
        <Typography color="#474747" fontSize="17.5px" margin="14px 0px">
          22,000,000+ Students. 10,000+ Schools. 500,000+ Educators.
        </Typography>

        <Grid
          container
          display="flex"
          alignItems="center"
          spacing={3}
          margin="10px 0px"
        >
          {educators.map((item) => (
            <Grid
              item
              xs={6}
              sm={3}
              display="flex"
              marginY="10px"
              justifyContent="center"
              key={item.img}
            >
              <img src={item.img} alt="educators logo" width={item.width} />
            </Grid>
          ))}
        </Grid>

        <Box width="100%">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            centerMode={false}
            customButtonGroup={<ButtonGroup />}
            draggable={true}
            focusOnSelect={false}
            infinite={true}
            keyBoardControl={true}
            minimumTouchDrag={80}
            renderButtonGroupOutside={true}
            responsive={responsive}
            showDots={true}
            dotListClass={classes.dots}
            renderDotsOutside={true}
            slidesToSlide={1}
          >
            {items.map((item) => (
              <CarouselCard
                key={item.id}
                text={item.text}
                name={item.name}
                designation={item.designation}
                address={item.address}
                classes={classes}
                gender={item.gender}
              />
            ))}
          </Carousel>
        </Box>
      </Box>
    </section>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      padding: '84px 28px',
    },
    image: {
      width: '110px',
    },
    card: {
      margin: '10px',
      borderRadius: '10px !important',
      boxShadow: '1px 1px 10px 0 rgb(0 0 0 / 15%) !important',
    },
    content: {
      padding: '60px !important',

      '@media(max-width:600px)': {
        padding: '20px !important',
      },
    },
    dots: {
      position: 'relative',
    },
  })
);
