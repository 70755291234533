import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Grid, Typography, Box } from '@mui/material';
import Journey from '../Assets/journey.png';
import useBreakpoints from '../Components/useBreakpoints';

export default function DeviceSection() {
  const classes = useStyles();
  const { md, lg, xl } = useBreakpoints();

  return (
    <Box className={classes.section}>
      <Grid
        container
        paddingX={xl ? '300px' : lg ? '250px' : '28px'}
        paddingBottom={!md && '20px'}
      >
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          order={!md && 2}
          paddingRight={md && '40px'}
          paddingTop={!md && '40px'}
        >
          <Typography
            fontSize={md ? '35px' : '30px'}
            fontWeight="bold"
            align="center"
          >
            THE JOURNEY TO YOUR DESTINATION.
          </Typography>
          <Typography fontSize="15px" align="center">
            EdVision empowers you by helping you connect with talented minds
            around the world to enhance your skills and become the best version
            of yourself.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          order={!md && 1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Journey} alt="devices" className={classes.image} />
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      backgroundColor: '#EEF7FF',
      padding: '80px 0px',
    },
    image: {
      height: '100%',
      maxHeight: '409px',
      width: '100%',
      maxWidth: '454px',
    },
  })
);
