import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import Button from '../Components/Button';
import bannerImage from '../Assets/bannerImage.png';
import feature1 from '../Assets/feature1.png';
import feature2 from '../Assets/feature2.png';
import feature3 from '../Assets/feature3.png';
import feature4 from '../Assets/feature4.png';
import feature5 from '../Assets/feature5.png';
import feature6 from '../Assets/feature6.png';
import useBreakpoints from '../Components/useBreakpoints';
import redirect from '../redirects';

const featured = [
  {
    img: feature1,
    width: '120px',
  },
  {
    img: feature2,
    width: '90px',
  },
  {
    img: feature3,
    width: '120px',
  },
  {
    img: feature4,
    width: '65px',
  },
  {
    img: feature5,
    width: '110px',
  },
  {
    img: feature6,
    width: '39px',
  },
];

export default function BannerSection() {
  const classes = useStyles();
  const { md, lg, xl } = useBreakpoints();

  return (
    <>
      <Box>
        <Grid
          container
          padding={xl ? '8rem 15rem' : lg ? '4rem 10rem' : '4rem 1rem'}
        >
          <Grid
            item
            xs={12}
            md={5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            order={!md && 2}
          >
            <Grid container spacing={2} padding={md && '0px 0px 0px 48px'}>
              <Grid item xs={12}>
                <Typography variant="h3" className="bold" align="center">
                  Start A
                </Typography>
                <Typography variant="h3" className="bold" align="center">
                  Free Trial Today
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.slogan} align="center">
                  EdVision is a platform to explore and experience a whole new
                  dimension of learning. We inculcate knowledge in you through
                  our platform, ensuring your enthusiasm to learn endlessly with
                  us.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex">
                  <Box width="100%" marginX="10px">
                    <Button
                      large
                      fullWidth
                      disableElevation
                      variant="contained"
                      subVariant="dark"
                      onClick={redirect.login}
                    >
                      Get more info
                    </Button>
                  </Box>

                  <Box width="100%" marginX="10px">
                    <Button
                      large
                      fullWidth
                      disableElevation
                      variant="contained"
                      subVariant="light"
                      onClick={redirect.login}
                    >
                      Explore
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
            md={7}
            order={!md && 1}
          >
            <img
              src={bannerImage}
              alt="people working"
              className={classes.image}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="button"
          align="center"
          letterSpacing="5px"
          color="#787878"
          fontSize={16}
          fontWeight="bold"
        >
          as featured in
        </Typography>
        <Grid container alignItems="center" margin="10px">
          {featured.map((item) => (
            <Grid
              item
              xs={6}
              sm={2}
              display="flex"
              marginY="10px"
              justifyContent="center"
              key={item.img}
            >
              <img src={item.img} alt="company logo" width={item.width} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    slogan: {
      color: 'rgba(0,0,0,.65)',
      fontSize: '1.25em !important',
    },
    image: {
      width: '100%',
    },
  })
);
