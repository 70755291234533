import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import useBreakpoints from '../Components/useBreakpoints';
import Button from '../Components/Button';
import ContactUsDialog from '../Components/Dialogs/ContactUsDialog';

const RaiseQuery = () => {
  const { md, sm } = useBreakpoints();

  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Box
      bgcolor="#eef7ff"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        borderBottomLeftRadius: `100% ${md ? '100px' : sm ? '80px' : '50px'}`,
        borderBottomRightRadius: `100% ${md ? '100px' : sm ? '80px' : '50px'}`,
      }}
    >
      <Box
        sx={{
          height: '50px',
          width: '100%',
          bgcolor: '#fff',
          borderBottomLeftRadius: `100% ${md ? '100px' : sm ? '80px' : '50px'}`,
          borderBottomRightRadius: `100% ${
            md ? '100px' : sm ? '80px' : '50px'
          }`,
        }}
      />

      <Box paddingY="40px">
        <Typography
          marginBottom="20px"
          fontSize={md ? '35px' : sm ? '30px' : '16px'}
          fontWeight="bold"
        >
          Raise A Query
        </Typography>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            subVariant="dark"
            disableElevation
            large
            onClick={handleOpenDialog}
          >
            Contact Us
          </Button>
        </Box>
      </Box>

      <ContactUsDialog open={open} close={handleCloseDialog} />
    </Box>
  );
};

export default RaiseQuery;
