import React, { Fragment } from 'react';
import { CssBaseline } from '@mui/material';
import './App.css';
import Layout from './Components/Layout';
import BannerSection from './Sections/BannerSection';
import FeaturesSection from './Sections/FeaturesSection';
import DeviceSection from './Sections/DeviceSection';
import ResearchSection from './Sections/ResearchSection';
import SupportSection from './Sections/SupportSection';
import CarouselSection from './Sections/CarouselSection';
import TrialSection from './Sections/TrialSection';
import PartnerSection from './Sections/PartnerSection';
import FooterSection from './Sections/FooterSection';
import ContactUsBtn from './Components/ContactUsBtn';
import Newsletter from './Sections/Newsletter';
import RaiseQuery from './Sections/RaiseQuery';
import RegisterNow from './Sections/RegisterNow';
import StillDoubt from './Sections/StillDoubt';

function App() {
  return (
    <Fragment>
      <CssBaseline />
      <Layout>
        <BannerSection />
        <Newsletter />
        <FeaturesSection />
        <DeviceSection />
        <ResearchSection />
        <SupportSection />
        <CarouselSection />
        <TrialSection />
        <RegisterNow />
        <PartnerSection />
        <RaiseQuery />
        <StillDoubt />
        <FooterSection />
        <ContactUsBtn />
      </Layout>
    </Fragment>
  );
}

export default App;
