import React from 'react';
import { Box, Typography } from '@mui/material';
import useBreakpoints from '../Components/useBreakpoints';
import Button from '../Components/Button';
import redirect from '../redirects';

const RegisterNow = () => {
  const { md, sm } = useBreakpoints();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        borderBottomLeftRadius: `100% ${md ? '100px' : sm ? '80px' : '50px'}`,
        borderBottomRightRadius: `100% ${md ? '100px' : sm ? '80px' : '50px'}`,
      }}
    >
      <Box
        sx={{
          height: '50px',
          width: '100%',
          bgcolor: '#fff',
          borderBottomLeftRadius: `100% ${md ? '100px' : sm ? '80px' : '50px'}`,
          borderBottomRightRadius: `100% ${
            md ? '100px' : sm ? '80px' : '50px'
          }`,
        }}
      />
      <Box paddingY="40px">
        <Typography
          marginBottom="20px"
          fontSize={md ? '35px' : sm ? '30px' : '16px'}
          fontWeight="bold"
        >
          Register Now For Free
        </Typography>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            subVariant="dark"
            disableElevation
            large
            fullWidth
            onClick={redirect.register}
          >
            Register
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterNow;
