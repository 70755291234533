import React, { useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  AppBar,
  Toolbar,
  Grid,
  ListItem,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GiHamburgerMenu } from 'react-icons/gi';
import Button from './Button';
import Logo from '../Assets/logo_trimmed.png';
import redirect from '../redirects';
import useBreakpoints from './useBreakpoints';

export default function MobileDrawer() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { sm } = useBreakpoints();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <GiHamburgerMenu />
      </IconButton>
      <Drawer
        variant="temporary"
        anchor="top"
        elevation={4}
        open={open}
        onClose={handleClose}
      >
        <AppBar className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              container
              poisition="relative"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                position="absolute"
                left={sm ? '0rem' : '30%'}
              >
                <img src={Logo} alt="EdVision" height="40px" width="150px" />
              </Box>

              <Grid item xs={4}></Grid>

              <Grid
                display="flex"
                alignItems="center"
                justifyContent="center"
                item
                xs={1}
              >
                <IconButton onClick={handleClose}>
                  <GiHamburgerMenu />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            marginTop: '50px',
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItem>
            <Button
              disableElevation
              fullWidth
              variant="contained"
              subVariant="light"
              onClick={redirect.login}
            >
              Sign In
            </Button>
          </ListItem>

          <ListItem>
            <Button
              disableElevation
              fullWidth
              variant="contained"
              subVariant="dark"
              onClick={redirect.register}
            >
              Request a trial
            </Button>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

const useStyles = makeStyles({
  listItemButton: {
    // border: '1px solid black !important',
  },
  appbar: {
    backgroundColor: '#fff !important',
    boxShadow: '1px 1px 3px 0 rgb(0 0 0 / 15%) !important',
  },
  toolbar: {
    padding: '0rem 20rem !important',
    color: '#000',

    '@media(max-width:1200px)': {
      padding: ' 0rem 1rem !important',
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
