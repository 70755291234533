import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import useBreakpoints from '../Components/useBreakpoints';

const Newsletter = () => {
  const { md, sm } = useBreakpoints();

  return (
    <Box
      bgcolor="#eef7ff"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        borderBottomLeftRadius: `100% ${md ? '100px' : sm ? '80px' : '50px'}`,
        borderBottomRightRadius: `100% ${md ? '100px' : sm ? '80px' : '50px'}`,
      }}
    >
      <Box
        sx={{
          height: '50px',
          width: '100%',
          bgcolor: '#fff',
          borderBottomLeftRadius: `100% ${md ? '100px' : sm ? '80px' : '50px'}`,
          borderBottomRightRadius: `100% ${
            md ? '100px' : sm ? '80px' : '50px'
          }`,
        }}
      />
      <Box paddingY="40px">
        <Typography
          marginBottom="20px"
          fontSize={md ? '35px' : sm ? '30px' : '16px'}
          fontWeight="bold"
          color="#002f6c"
        >
          SUBSCRIBE FOR OUR NEWSLETTER
        </Typography>

        <Box display="flex" justifyContent="center">
          <TextField
            placeholder="Email"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '0px',
                width: sm ? '400px' : '200px',

                '& fieldset': {
                  borderColor: '#000',
                  boxShadow: '0px 0px 0px 2px #000',
                },

                '&:hover fieldset': {
                  borderColor: '#000',
                },

                '&.Mui-focused fieldset': {
                  borderWidth: '1px',
                  borderColor: '#000',
                },
              },
            }}
          />
          <Button
            variant="outlined"
            sx={{
              borderRadius: '0px',
              borderColor: '#000',
              color: '#000',
              borderLeft: '0px solid #000',
              boxShadow: '0px 0px 0px 2px #000',
              fontWeight: 'bold',

              '&:hover': {
                borderColor: '#000',
                color: '#000',
                borderLeft: '0px solid #000',
              },
            }}
          >
            Done
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Newsletter;
