import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import useBreakpoints from './useBreakpoints';
import ContactUsDialog from './Dialogs/ContactUsDialog';

export default function ContactUsBtn() {
  const { sm } = useBreakpoints();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  if (sm) {
    return (
      <>
        <Box
          sx={{
            bgcolor: '#0c65b8',
            position: 'fixed',
            transform: 'rotate(-90deg)',
            msTransform: 'rotate(-90deg)',
            WebkitTransform: 'rotate(-90deg)',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            padding: '0.8rem',
            cursor: 'pointer',
            right: '-50px',
            top: '50%',
          }}
          onClick={handleOpenDialog}
        >
          <Typography
            color="#fff"
            fontWeight="bold"
            variant="h5"
            className="disable-select"
          >
            Contact Us
          </Typography>
        </Box>
        <ContactUsDialog open={open} close={handleCloseDialog} />
      </>
    );
  } else {
    return null;
  }
}
