import React from 'react';
import {
  AppBar,
  useScrollTrigger,
  Slide,
  Toolbar,
  Grid,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Button from './Button';
import useBreakpoints from './useBreakpoints';
import MobileDrawer from './MobileDrawer';
import Logo from '../Assets/logo_trimmed.png';
import redirect from '../redirects';

const HideOnScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default function Layout(props) {
  const { children } = props;
  const classes = useStyles();

  const { sm, md } = useBreakpoints();

  return (
    <>
      <HideOnScroll {...props}>
        <AppBar className={classes.appbar}>
          <Toolbar className={classes.toolbar}>
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              container
              position="relative"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                position="absolute"
                left={sm ? '0rem' : '30%'}
              >
                <img src={Logo} alt="EdVision" height="40px" width="150px" />
              </Box>

              <Grid item xs={4}></Grid>

              {md ? (
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={6} className={classes.menuItems}></Grid>
                    <Grid item xs={1} className={classes.iconContainer}></Grid>
                    <Grid item xs={5}>
                      <Grid container>
                        <Grid item xs={5}>
                          <Button
                            variant="contained"
                            subVariant="light"
                            disableElevation
                            onClick={redirect.login}
                          >
                            Sign In
                          </Button>
                        </Grid>

                        <Grid item xs={7}>
                          <Button
                            variant="contained"
                            subVariant="dark"
                            disableElevation
                            onClick={redirect.register}
                          >
                            Request a trial
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  item
                  xs={1}
                >
                  <MobileDrawer />
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      {children}
    </>
  );
}

const useStyles = makeStyles({
  appbar: {
    backgroundColor: '#fff !important',
    boxShadow: '1px 1px 3px 0 rgb(0 0 0 / 15%) !important',
  },
  toolbar: {
    padding: '0rem 20rem !important',
    color: '#000',

    '@media(max-width:1500px)': {
      padding: ' 0rem 15rem !important',
    },

    '@media(max-width:1281px)': {
      padding: ' 0rem 1rem !important',
    },
  },
  menuItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
