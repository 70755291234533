import React from 'react';
import {
  Box,
  Card,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  IconButton,
} from '@mui/material';
import useBreakpoints from '../useBreakpoints';
import { BsFillPersonFill } from 'react-icons/bs';
import { AiTwotoneMail, AiFillPhone } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';
import Button from '../Button';
import { useFormik } from 'formik';
import axios from 'axios';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  } else if (!/^[0-9]{10}$/i.test(values.phoneNumber)) {
    errors.phoneNumber = 'Invalid phone number';
  }

  if (!values.comment) {
    errors.comment = 'Required';
  } else if (values.comment.length < 10) {
    errors.comment = 'Comment must be at least 10 characters long';
  }

  return errors;
};

export default function ContactUsDialog({ open, close }) {
  const { xl } = useBreakpoints();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      comment: '',
    },
    validate,
    onSubmit: async (values) => {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/contact`,
        values
      );

      if (res.status === 200) {
        alert('Your message has been sent successfully');
        formik.resetForm();
      }
    },
    validateOnChange: false,
  });

  const handleClose = () => {
    console.log('test');
    close();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <IconButton
        sx={{ position: 'absolute', top: '10px', right: '10px' }}
        onClick={handleClose}
      >
        <IoClose />
      </IconButton>

      <Box padding="35px">
        <Typography
          fontWeight="bold"
          color="#002f6c"
          fontSize="35px"
          align="center"
        >
          Contact Us
        </Typography>
        <Card elevation={0}>
          <TextBox xl={xl}>
            <Typography fontWeight="bold">NAME</Typography>
            <TextField
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <BsFillPersonFill />
                  </InputAdornment>
                ),
              }}
              error={formik.errors.name}
              helperText={formik.errors.name || '\u00a0'}
            />
          </TextBox>

          <TextBox xl={xl}>
            <Typography fontWeight="bold">EMAIL</Typography>
            <TextField
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AiTwotoneMail />
                  </InputAdornment>
                ),
              }}
              error={formik.errors.email}
              helperText={formik.errors.email || '\u00a0'}
            />
          </TextBox>

          <TextBox>
            <Typography fontWeight="bold" type="number">
              PHONE NUMBER
            </Typography>
            <TextField
              id="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AiFillPhone />
                  </InputAdornment>
                ),
              }}
              error={formik.errors.phoneNumber}
              helperText={formik.errors.phoneNumber || '\u00a0'}
            />
          </TextBox>

          <TextBox>
            <Typography fontWeight="bold">QUERY</Typography>
            <TextField
              multiline
              minRows={4}
              id="comment"
              value={formik.values.comment}
              onChange={formik.handleChange}
              error={formik.errors.comment}
              helperText={formik.errors.comment || '\u00a0'}
            />
          </TextBox>

          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              disableElevation
              large
              variant="contained"
              subVariant="dark"
              onClick={formik.handleSubmit}
            >
              Submit Query
            </Button>
          </Box>
        </Card>
      </Box>
    </Dialog>
  );
}

const TextBox = ({ children }) => (
  <Box display="flex" flexDirection="column" width="100%">
    {children}
  </Box>
);
