import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Grid, Typography, Box } from '@mui/material';
import Walking from '../Assets/walking.jpeg';
import useBreakpoints from '../Components/useBreakpoints';

export default function SupportSection() {
  const classes = useStyles();
  const { md, lg, xl } = useBreakpoints();

  return (
    <Box className={classes.section}>
      <Grid
        container
        paddingX={xl ? '300px' : lg ? '250px' : '28px'}
        paddingBottom={!md && '20px'}
      >
        <Grid
          item
          xs={12}
          md={7}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          order={!md && 2}
          paddingRight={md && '40px'}
          paddingTop={!md && '40px'}
        >
          <Typography
            fontSize={md ? '35px' : '30px'}
            fontWeight="bold"
            align="center"
          >
            DEDICATED IMPLEMENTATION AND A+ SUPPORT
          </Typography>
          <Typography fontSize="15px" align="center">
            With an average response time of 30 seconds, our world-class support
            team is here to help you solve virtually any problem. We even
            provide direct support for your teachers. And our dedicated
            implementation team ensures you’re set up for success from the
            moment you deploy Edvision.³
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          order={!md && 1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Walking} alt="devices" className={classes.image} />
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    section: {
      backgroundColor: '#EEF7FF',
      padding: '80px 0px',
    },
    image: {
      height: '100%',
      maxHeight: '409px',
      width: '100%',
      maxWidth: '454px',
    },
  })
);
