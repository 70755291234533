const redirect = {
  login: () => {
    window.location.href = 'https://www.class.edvision.io/auth/login';
  },
  register: () => {
    window.location.href = 'https://www.class.edvision.io/auth/signup';
  },
};

export default redirect;
